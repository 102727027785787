import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import logo from '../../assets/images/pd/logo.png'
import docs from '../../assets/images/pd/match-doctors.png'
import mobeval from '../../assets/images/pd/mobile-eval.png'
import docprofile from '../../assets/images/pd/doctor-profile.jpg'
import algsearch from '../../assets/images/pd/algolia-search.jpg'
import landing from '../../assets/images/pd/landingpage.jpg'
import evalpain from '../../assets/images/pd/evaluation-painscale.jpg'
import evalarea from '../../assets/images/pd/evaluation-painarea.jpg'
import forestadmin from '../../assets/images/pd/forest-admin.jpg'
import favicon from '../../assets/images/pd/favicon.png'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">FULL STACK</Text>
                                <img
                                    src={logo}
                                    alt="Pain Diagnosed"
                                    css={`
                                        max-width: 450px;
                                    `}
                                />
                                <Text
                                    variant="p"
                                    css={`
                                        max-width: 750px;
                                    `}
                                >
                                    Prior to covid, doctors were struggling to
                                    find suitable telehealth products for their
                                    patients. My company saw a need for a portal
                                    to match patients with local doctors. The
                                    site was an evolution of prior development
                                    versions of collective care and cloud9spine.
                                </Text>
                                <Text
                                    variant="p"
                                    css={`
                                        margin-top: 1.5em;
                                        max-width: 750px;
                                    `}
                                >
                                    As the sole developer, I needed to focus on
                                    encapsulating 3rd party services. The entire
                                    project took several months to complete, but
                                    when finished now requires very little
                                    effort to update and maintain.
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={docs}
                                    alt="Match Doctors"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Novatex
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    May 8th, 2020
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.paindiagnosed.com"
                                    >
                                        paindiagnosed.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={mobeval}
                                    alt="Mobile Evaluation"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    The Pain Evaluation
                                </Title>
                                <Text variant="p">
                                    I started developing web surveys over 2
                                    decades ago. Each survey was an evolution of
                                    the next. The pain evaluation is a very
                                    simple survey that engages with patients
                                    looking for help with their pain. After a
                                    patient steps through a series of questions
                                    I was able to match them with doctors that
                                    best suited their treatment requirements.
                                </Text>
                                <Text
                                    variant="p"
                                    css={`
                                        margin-top: 1.5em;
                                    `}
                                >
                                    The original pain evaluation was developed
                                    to generate matches for possible conditions
                                    (a symptom checker) but was eventually
                                    changed to an engagement channel for
                                    paitients to connect with pain specialists.
                                </Text>
                                <Text
                                    variant="p"
                                    css={`
                                        margin-top: 1.5em;
                                    `}
                                >
                                    Over the past year the evaluations has
                                    helped connect thousands of patients with
                                    doctors throughout the United States.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="algolia" title="01. Algolia Search" left>
                        I needed a search engine for patients looking for local
                        doctors that could provide similar capabilities as
                        zocdoc. I didn't want to develop the search internally
                        as I was the only developer so I chose to integrate
                        Algolia Search.
                    </Key>

                    <Key name="gatsby" title="02. Gatsby &amp; React">
                        React has been my goto frontend development toolkit for
                        a few years now. I can't imagine developing frontend UI
                        without it. GatsbyJS was new on the scene but I could
                        tell I wanted to attempt integrating my React components
                        with its static rendering capabilities.
                    </Key>

                    <Key name="forest" title="03. Forest Admin" left>
                        I knew I was going to need an admin capable of manaing
                        thousands of doctors accounts, locations, specialties
                        and insurances. A relatively unknown product at the
                        time, Forest Admin showed its potential to generate data
                        models from an existing database and deploy to
                        production in 5 minutes.
                    </Key>

                    <Key name="stripe" title="04. Stripe">
                        I had used stripe as a payment portal in the past. It
                        provides payment gateway services with very little
                        effort to the developer. Highly recommended when
                        building services using a rapid application development
                        (RAD) workflow.
                    </Key>

                    <Key
                        name="googlecloud"
                        title="05. Google & Kubernetes"
                        left
                    >
                        I had been developing full stack on Amazon Web Services
                        (AWS) for over 10 years. Google Cloud Platform (GCP) was
                        offering a promotion on their web services which I
                        couldn't pass up. I'm glad I did because the interfaces
                        were refreshingly simple, very google'esk, and I was up
                        and running a fully scaleable environment for my docker
                        service modules.
                    </Key>
                    <Key name="mobile" title="06. Mobile 1st Responsive">
                        A key strategy I used in the healthcare marketing space
                        was mobile marketing, that is, providing the best
                        experience to visitors coming to my sites on mobile
                        devices. A complicated feature that is usually
                        overlooked but provides am abundance of less expensive
                        paid marketing channels.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={landing}
                                        alt="Pain Diagnosed Landing Page"
                                    />
                                    <Screenshot
                                        src={docprofile}
                                        alt="Pain Diagnosed Doctor Profile"
                                    />
                                    <Screenshot
                                        src={algsearch}
                                        alt="Pain Diagnosed Algolia Search"
                                    />
                                    <Screenshot
                                        src={evalarea}
                                        alt="Pain Diagnosed Evaluation Pain Area"
                                    />

                                    <Screenshot
                                        src={forestadmin}
                                        alt="Pain Diagnosed Forest Admin"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/accountable-ads/">
                                Accountable Ads
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
